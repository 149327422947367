import { ImageModel } from '@/models/image-model'
import { PhotoService } from '@/services/patient-photo-service'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Alert from '@/components/shared/Alert.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { ErrorService } from '@/services/error.service'
import { Subscription } from 'rxjs'
import { defaultItemsPerPage, defaultItemsPerPageWithAll } from '@/shared/constants/Constants'

@Component({
  components: {
    Alert,
    Confirm
  }
})

export default class Photos extends Vue {
  private readonly BASE_64_PHOTO_PREFIX = 'data:image/jpeg;base64,'
  private readonly photoService = PhotoService.getInstance()
  private subcription!: Subscription

  @Prop()
  public dossierId!: string

  @Prop()
  public podoPhotoType!: number

  public headersPhotos = [
    { text: 'Photo', value: 'image', sortable: false },
    { text: 'Date', value: 'createdAt' },
    { text: 'Insérée par', value: 'createdBy' },
    { text: 'Description', value: 'description' },
    { text: '', value: 'actions', sortable: false, align: 'right' }
  ]

  public photos: ImageModel[] = []
  public uploadPhotos: ImageModel[] = []
  public isSaving = false
  public showDialog = false
  public showConfirm = false
  public showOverlay = false
  public initLoading = false
  public imageLoading = false
  public selectedPhoto: ImageModel = {}
  public files: File[] = []
  public errorMessages: string[] = []
  public fetchedImage: ImageModel = { dimensions: { width: 0, height: 0 } }
  public picScale = 1
  public defaultItemsPerPageWithAll = defaultItemsPerPageWithAll
  public defaultItemsPerPage = defaultItemsPerPage

  public mounted () {
    document.onkeyup = (evt) => {
      evt.stopImmediatePropagation()
      if (evt.key === 'Escape') this.closeOverlay()
    }
    const GUID_LEN = 36
    if (this.dossierId.length !== GUID_LEN) {
      this.errorMessages.push('Invalid dossierId provided')
    } else {
      this.getPhotos()
    }

    this.subcription = this.photoService.uploadErrors$.subscribe(async errs => {
      if (errs.err) {
        const res = await ErrorService.handleError(errs.err)
        this.errorMessages.push(`${res.errors[0]}  (${errs.image.name})`)
      }
    })
    this.hideAlert()
  }

  public get totalFileSize () {
    const size = this.uploadPhotos.map(image => image.filesize ?? 0)
      .reduce((acc, value) => acc + value, 0)
    return (size / 1e+6).toFixed(2)
  }

  public get isOverflow () {
    return +this.totalFileSize > 100
  }

  private getPhotos () {
    this.initLoading = true
    this.photoService.GetPatientImages(this.dossierId, this.podoPhotoType)
      .then(photos => {
        this.photos = photos
      })
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.initLoading = false
      })
  }

  public onChange (event: Event) {
    const fileList = (event.target as HTMLInputElement).files as FileList
    this.handleFileInput(fileList)
  }

  private handleFileInput (fileList: FileList) {
    this.files = [...fileList]
    this.files.forEach(file => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const image: ImageModel = {
          description: '',
          name: file.name,
          filesize: file.size,
          imageUrl: reader.result as string,
          type: this.podoPhotoType
        }
        this.uploadPhotos.push(image)
      }
    })
  }

  private convertBase64ToArrayBuffer (base64String: string) {
    const BASE64_MARKER = ';base64,'
    const base64Index = base64String.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    const binString = atob(base64String.substring(base64Index))
    const len = binString.length
    const bytes = new Uint8Array(new ArrayBuffer(len))
    for (let i = 0; i < len; i++) {
      bytes[i] = binString.charCodeAt(i)
    }
    return bytes
  }

  public remove (idx: number) {
    this.uploadPhotos.splice(idx, 1)
  }

  public dragover (event: Event) {
    event.preventDefault()
    if (!(event.currentTarget as HTMLInputElement).classList.contains('on-hover')) {
      (event.currentTarget as HTMLInputElement).classList.add('on-hover')
    }
  }

  public dragleave (event: Event) {
    (event.currentTarget as HTMLInputElement).classList.remove('on-hover')
  }

  public drop (event: DragEvent) {
    event.preventDefault()
    const dropFiles = (event.dataTransfer as DataTransfer).files
    this.handleFileInput(dropFiles)
    const target = event.currentTarget as HTMLInputElement
    target.classList.remove('on-hover')
  }

  public async fetchPhoto (id: string) {
    this.imageLoading = true
    this.showOverlay = true
    const photo = await this.photoService.GetImage(id)
    this.fetchedImage = { ...photo as ImageModel, dimensions: this.fetchedImage.dimensions }
    this.fetchedImage.imageUrl = `${this.BASE_64_PHOTO_PREFIX}${photo?.image}`
    this.fetchedImage.dimensions = await this.loadImage(`${this.BASE_64_PHOTO_PREFIX}${photo?.image}`)
    this.imageLoading = false
  }

  public async loadImage (base64String? : string) {
    return new Promise<{ width: number; height: number }>(resolve => {
      if (base64String) {
        const i = new Image()
        i.onload = () => {
          resolve({ width: i.width, height: i.height })
        }
        i.src = base64String as string
      }
    })
  }

  public closeOverlay () {
    this.showOverlay = false
    // this.layoutService.updateState(true)
    this.picScale = 1
    this.fetchedImage = { dimensions: { width: 0, height: 0 } }
  }

  public saveToLocal () {
    const link = document.createElement('a')
    document.body.appendChild(link) // for Firefox
    link.setAttribute('href', this.fetchedImage.imageUrl as string)
    link.setAttribute('download',
    `${this.fetchedImage.description?.substring(0, 5)}-${this.fetchedImage.type}-${this.fetchedImage.createdBy}@${this.fetchedImage.createdAt}.jpg`)
    link.click()
    document.body.removeChild(link)
  }

  public resetFiles () {
    this.uploadPhotos = []
    this.files = []
  }

  public async save () {
    this.isSaving = true
    if (this.isOverflow) return
    this.errorMessages = []
    this.uploadPhotos.forEach(uploadPhoto => {
      uploadPhoto.image = [...this.convertBase64ToArrayBuffer(uploadPhoto.imageUrl as string)]
    })
    await this.photoService.AddPatientPhotos(this.dossierId, this.uploadPhotos)
      .finally(() => {
        this.isSaving = false
        this.getPhotos()
      })
  }

  public editPhoto (photo: ImageModel) {
    this.showDialog = true
    this.selectedPhoto = photo
  }

  public async updateImage () {
    this.isSaving = true
    const res = await this.photoService.updatePatientImage(this.dossierId, this.selectedPhoto)
      .catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.errorMessages = res.errors
      })
      .finally(() => {
        this.isSaving = false
      })
    if (res) {
      this.getPhotos()
      this.closeDialog()
    }
  }

  public displayDeleteConfirmDialog (photo: ImageModel) {
    this.showConfirm = true
    this.selectedPhoto = photo
  }

  public async confirmDeleteCallback (value: boolean) {
    this.showConfirm = false
    if (value) {
      const results = await this.photoService.deletePatientPhoto(this.selectedPhoto.id)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
      if (results === 200) {
        this.errorMessages = []
        this.selectedPhoto = {}
        this.getPhotos()
      }
    }
  }

  public closeDialog () {
    this.showDialog = false
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public destroyed () {
    this.subcription.unsubscribe()
  }
}
