import http from '@/http-client'
import { ImageModel } from '@/models/image-model'
import { BehaviorSubject } from 'rxjs'

export class PhotoService {
  private static instance: PhotoService
  private _uploadErrors = new BehaviorSubject<any>('')
  public uploadErrors$ = this._uploadErrors.asObservable()

  public static getInstance (): PhotoService {
    if (!PhotoService.instance) {
      PhotoService.instance = new PhotoService()
    }
    return PhotoService.instance
  }

  // photos CRUD request
  public async AddPatientPhotos (dossierId: string, images: ImageModel[]) {
    return Promise.allSettled(images.map(async (image, _, imgs) => {
      image.dossierId = dossierId
      await http.post(`/dossierPatient/image/${dossierId}`, [image])
        .catch((err) => {
          this._uploadErrors.next({ err, image })
        })
        .finally(() => {
          imgs.splice(imgs.indexOf(image))
        })
    }))
  }

  public async updatePatientImage (dossierId: string, image: ImageModel) {
    const results = await http.put(`/dossierPatient/image/${dossierId}`, image)
    return results.data as number
  }

  public async GetImage (imageId: string) {
    if (!imageId) return
    const response = await http.get(`/dossierPatient/image/${imageId}`)
    return (response.data) as ImageModel
  }

  public async GetPatientImages (dossierId: string, photoType: number) {
    const response = await http.get(`/dossierPatient/images/${dossierId}/${photoType}`)
    return (response.data) as ImageModel[]
  }

  public async deletePatientPhoto (id: string | undefined) {
    if (!id) return
    const res = await http.delete(`/dossierPatient/image/${id}`)
    return res.status as number
  }
}
